import cn from 'classnames';
import { ProductCategoryWarranty } from '@lambda/apis/src/category/types';
import { handleFreeReturn } from '@lambda/commons/helpers';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { HEALTH_BEAUTY_CATEGORIES } from '@/settings/configs';
import { generateWarrantyLabel, getPreTitle } from './helpers';
import Themes from './Themes';
import settings from '@/settings/index';
import {
  BOXING_DAY_END_DATE,
  BOXING_DAY_START_DATE,
  HOLIDAYS_START_DATE,
} from '@/lib/holidays/constants';
import TrustPilot from './TrustPilot';

dayjs.extend(utc);
dayjs.extend(tz);

export const generateTheme = () => {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // Starting From December 25, 2024 until December 26, 2024
  if (
    dayjs.utc().tz(storeTimeZone).isSameOrAfter(BOXING_DAY_START_DATE) &&
    dayjs.utc().tz(storeTimeZone).isBefore(BOXING_DAY_END_DATE) &&
    ['reebelo-ca', 'reebelo-au'].includes(store)
  )
    return 'BOXING_DAY';

  // Starting From December 7, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(HOLIDAYS_START_DATE))
    return 'HOLIDAYS';

  return 'DEFAULT';
};

export default function CollectionBanner(props: {
  title: string;
  searchResult?: boolean;
  category?: string;
  categoryWarrantyInfo?: ProductCategoryWarranty | null;
}) {
  const {
    title,
    category,
    categoryWarrantyInfo = null,
    searchResult = false,
  } = props;
  const isUSStore = settings.store === 'reebelo-us';

  const isHealthBeautyCollection =
    !isUSStore && HEALTH_BEAUTY_CATEGORIES.includes(category ?? '');

  const preTitle = getPreTitle(isHealthBeautyCollection, searchResult);
  const warrantyLabel = generateWarrantyLabel(category, categoryWarrantyInfo);

  const freeReturnDays = handleFreeReturn(
    settings.store,
    settings.free_return_days,
  );

  const theme = generateTheme();

  return (
    <div className="md:reebelo-container md:pt-5">
      <div
        className={cn(
          'relative mx-auto flex flex-col justify-between overflow-hidden bg-gray-700 text-white md:rounded-md',
          'px-6 md:px-5 lg:px-7 xl:px-8',
          'py-6 md:py-4 lg:py-6 xl:py-7',
          {
            'bg-transparent bg-gradient-to-r from-[#FFF1DD] to-[#ECD5B6] !text-gray-700':
              isHealthBeautyCollection,
          },
          {
            '!bg-[#f5ffff] !text-gray-700 shadow': theme === 'BOXING_DAY',
          },
          {
            '!bg-gradient-to-r from-[#EF5863] to-[#C52237] !text-white':
              theme === 'HOLIDAYS',
          },
        )}
      >
        <div className="w-full xxs:w-[85%] sm:w-[83%] lg:w-[83%]">
          <Themes
            isHealthBeautyCollection={isHealthBeautyCollection}
            theme={theme}
          />
          <div className="relative">
            <h1 className="text-lg font-bold leading-tight md:text-xl">
              {preTitle}{' '}
              <span
                className={cn('text-teal-500', {
                  '!text-white':
                    isHealthBeautyCollection || theme === 'HOLIDAYS',
                  '!text-gray-700': theme === 'BOXING_DAY',
                })}
              >
                {title}
              </span>
            </h1>
            <div className="mt-5 flex items-center justify-between">
              <div className="flex flex-wrap gap-y-1 gap-x-2 text-xxs font-bold xxs:text-xs md:gap-x-3 md:text-sm xl:text-base">
                <div>{warrantyLabel}</div>
                <div>|</div>
                <div>{freeReturnDays} Days Return</div>
                <div>|</div>
                <div>
                  <span className="mr-1 hidden md:inline-block">
                    500,000+ customers
                  </span>
                  <span className="inline-block md:hidden">
                    <TrustPilot
                      isHealthBeautyCollection={isHealthBeautyCollection}
                      theme={theme}
                    />
                  </span>
                </div>
              </div>
              <div className="hidden md:block">
                <TrustPilot
                  isHealthBeautyCollection={isHealthBeautyCollection}
                  theme={theme}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
