import Image from 'next/future/image';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const CyberMonday = () => {
  const cyberMondayImage = getEdgeUrl(
    'images/collections/banner/cyber-monday/cyber-monday-desktop.png',
  );

  return (
    <div className="absolute bottom-0 right-0 h-full xs:right-[-55px] sm:right-[-20px] md:right-0">
      <Image
        src={cyberMondayImage}
        alt="phones"
        loading="eager"
        className="h-full w-auto rounded-r-md object-cover object-left opacity-20 xs:opacity-100"
        width={292}
        height={206}
        quality={100}
      />
    </div>
  );
};

export default CyberMonday;
