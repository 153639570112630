import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import dynamic from 'next/dynamic';
import settings from '@/settings';
import {
  BLACK_FRIDAY_START_DATE,
  CYBER_MONDAY_START_DATE,
} from '@/lib/holidays/constants';
import CyberMonday from './CyberMonday';
import BoxingDay from './BoxingDay';

const Default = dynamic(() => import('./Default'), {
  ssr: true,
});
const BlackFriday = dynamic(() => import('./BlackFriday'), {
  ssr: true,
});
const Holidays = dynamic(() => import('./Holidays'), {
  ssr: true,
});

dayjs.extend(utc);
dayjs.extend(tz);

const Themes = ({
  isHealthBeautyCollection,
  theme,
}: {
  isHealthBeautyCollection: boolean;
  theme: 'BOXING_DAY' | 'HOLIDAYS' | 'DEFAULT';
}) => {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // Starting From December 25, 2024 until December 26th
  if (theme === 'BOXING_DAY') return <BoxingDay />;

  // Starting From December 7, 2024
  if (theme === 'HOLIDAYS') return <Holidays />;

  // Starting From November 30, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_MONDAY_START_DATE))
    return <CyberMonday />;

  // Starting From November 20, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(BLACK_FRIDAY_START_DATE))
    return <BlackFriday />;

  return <Default isHealthBeautyCollection={isHealthBeautyCollection} />;
};

export default Themes;
