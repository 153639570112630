import { ProductCategoryWarranty } from '@lambda/apis/src/category/types';
import { formatWarrantyInfo } from '@/lib/warranty';
import { NON_TECH_CATEGORIES } from '@/settings/configs';
import trustpilotFullStar from '@/public/images/banner/trustpilot_full_star.svg';
import trustpilotHalfStar from '@/public/images/banner/trustpilot_half_star.svg';
import trustpilotFullStarBlack from '@/public/images/banner/trustpilot_full_star_black.svg';
import trustpilotHalfStarBlack from '@/public/images/banner/trustpilot_half_star_black.svg';
import trustpilotFullStarRed from '@/public/images/banner/trustpilot_full_star_red.svg';
import trustpilotHalfStarRed from '@/public/images/banner/trustpilot_half_star_red.svg';
import settings from '@/settings';

export const generateWarrantyLabel = (
  category: string | undefined,
  categoryWarrantyInfo: ProductCategoryWarranty | null,
) => {
  const isTechCollection = !NON_TECH_CATEGORIES.includes(category ?? '');

  if (!isTechCollection) return 'Fast & Free Shipping';
  if (settings.store === 'quista') return 'Up to 12 Months Warranty';

  const formattedWarrantyInfo = formatWarrantyInfo(categoryWarrantyInfo);

  if (categoryWarrantyInfo?.eligible)
    return `${formattedWarrantyInfo} Warranty`;

  // TODO deprecate store specific warranty
  if (settings.warranty_month === 12) return '1 Year Warranty';

  return `${settings.warranty_month} Month Warranty`;
};

export const getStars = ({
  isHealthBeautyCollection,
  theme,
}: {
  isHealthBeautyCollection: boolean;
  theme: 'BOXING_DAY' | 'HOLIDAYS' | 'DEFAULT';
}) => {
  if (isHealthBeautyCollection) {
    return {
      fullStar: trustpilotFullStarBlack,
      halfStar: trustpilotHalfStarBlack,
    };
  }

  if (theme === 'HOLIDAYS') {
    return {
      fullStar: trustpilotFullStarRed,
      halfStar: trustpilotHalfStarRed,
    };
  }

  return {
    fullStar: trustpilotFullStar,
    halfStar: trustpilotHalfStar,
  };
};

export const getPreTitle = (
  isHealthBeautyCollection: boolean,
  searchResult: boolean,
) => {
  const isCertifiedRefurbished = [
    'reebelo-ca',
    'reebelo-us',
    'reebelo-au',
  ].includes(settings.store);

  if (isHealthBeautyCollection) return 'Sustainable';
  if (searchResult) return 'Search results for';
  if (isCertifiedRefurbished) return `Certified Refurbished`;

  return `Refurbished`;
};
