import Image from 'next/future/image';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const BoxingDay = () => {
  const HolidaysImage = getEdgeUrl(
    'images/collections/banner/boxing-day/desktop.png',
  );

  return (
    <div className="absolute bottom-0 right-0 h-full xxs:right-[-70px] xs:right-[-40px] sm:right-0">
      <Image
        src={HolidaysImage}
        alt="Gadgets"
        loading="eager"
        className="h-full w-auto rounded-r-md object-cover object-left opacity-30 xxs:opacity-100"
        width={292}
        height={206}
        quality={100}
      />
    </div>
  );
};

export default BoxingDay;
