import Image from 'next/future/image';
import cn from 'classnames';
import { TrustpilotStar as TrustpilotLogo } from '../../../../products/TrustpilotWidget';
import { getStars } from './helpers';

const TrustpilotStar = (imgProps: { imageSrc: StaticImageData }) => (
  <div className="relative h-4 w-4 xl:h-5 xl:w-5">
    <Image
      src={imgProps.imageSrc}
      alt="trustpilot stars"
      className="h-full w-full object-contain"
    />
  </div>
);

const TrustPilot = ({
  isHealthBeautyCollection,
  theme,
}: {
  isHealthBeautyCollection: boolean;
  theme: 'BOXING_DAY' | 'HOLIDAYS' | 'DEFAULT';
}) => {
  const stars = getStars({ isHealthBeautyCollection, theme });

  return (
    <div className="flex items-center text-xxs xxs:text-xs md:text-sm xl:text-base">
      <span className="hidden font-normal lg:inline-block">Excellent on</span>
      <span className="inline-block justify-between font-bold lg:hidden">
        4.7{' '}
      </span>
      <div className="mx-1 mb-[3px] xl:mx-2">
        <TrustpilotLogo
          className={cn(
            'h-[13px] w-[13px] md:h-[15px] md:w-[15px] lg:h-4 lg:w-4 xl:h-5 xl:w-5',
          )}
          isWhite={!isHealthBeautyCollection && theme !== 'BOXING_DAY'}
        />
      </div>
      <span className="mr-1 inline-block font-bold lg:hidden">on</span>
      <b>Trustpilot</b>
      <div className="ml-2 hidden items-center justify-start lg:flex">
        {[...Array(4)].map((_, index) => (
          <TrustpilotStar key={index} imageSrc={stars.fullStar} />
        ))}
        <TrustpilotStar imageSrc={stars.halfStar} />
      </div>
    </div>
  );
};

export default TrustPilot;
